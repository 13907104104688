@import '../../../../scss/theme-bootstrap';

.gnav-submenu-layout {
  &__container {
    background-color: $color-white;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    .block[class*='tout'] {
      display: none;
    }

    @media #{$cr19-large-up} {
      column-gap: 1.5rem;
      grid-template-columns: repeat(5, 1fr);

      .block[class*='tout'] {
        display: block;
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
}
